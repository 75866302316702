<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="300"
    scrollable
    persistent
  >
    <ClientPasswordForm
      :errors="contactPasswordValidationErrors"
      :disabled="$store.getters.loading[`post:api/users/create-by-person-id/${selectedContact.id}`]"
      @clear:errors="clearContactValidationErrors"
      @cancel="goToClientsPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import ClientPasswordForm from '@/components/ClientPasswordForm';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'CreateClientLogin',

  components: {
    ClientPasswordForm,
  },

  created() {
    this.SET_SELECTED_CONTACT(this.$route.params);
  },

  computed: {
    ...mapState('contacts', ['selectedContact', 'contactPasswordValidationErrors']),
    ...mapState('clients', ['clientFilterParams']),
  },

  methods: {
    ...mapMutations('contacts', ['SET_SELECTED_CONTACT']),
    ...mapActions('contacts', ['createLogin', 'clearContactValidationErrors']),

    onSave(event) {
      const payload = {
        id: this.selectedContact.id,
        pass: event,
      };
      this.createLogin(payload).then(() => this.goToClientsPage());
    },

    goToClientsPage() {
      this.$router.push({ name: 'clients', query: this.clientFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
